import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CheckToken, atualizarToken } from '../../utils'
import { GlobalContext } from '../../contexts/AppContext'
import { postAPIData } from '../../utils'

// VerifyAuth is a component that verifies if the user is authenticated
// if not, it redirects to the login page
const VerifyAuth = ({isAuth, setIsAuth}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { appData, setAppData } = useContext(GlobalContext)
  const staticData = appData

  useEffect(() => {
    // armazenar ultima pagina visitada em appData
    const pathName = window.location.pathname

    if (!pathName.match(/\/(login|logout|recuperar|reenviar|cadastro|confirmar)/)) {
      setAppData((prev) => {
        return {
          ...prev,
          lastPage: pathName
        }
      })
    }
  }, [setAppData, location])

  // invocar o update-last-activity a cada page load
  useEffect(() => {
    const updateLastActivity = async () => {
      if (!appData.userData?.id) return

      try {
        const data = await postAPIData('/user/update-last-activity', { user_id: appData.userData.id })
        if (data.status === 200) {
          // console.log('update-last-activity')
        }
      } catch (error) {
        console.log(error)
      }
    }
    updateLastActivity()
  }, [appData.userData?.id, location])



  // useEffect para verificar se o usuario esta autenticado
  useEffect(() => {
    // authRoutesExpr tem a lista de páginas que precisam de autenticação
    // usar regex para verificar se o pathname corresponde a alguma rota
    const authRoutesExpr = [
      '/dashboard*',
    ]
    const pathName = location.pathname
    const validToken = CheckToken()
    setIsAuth(validToken)

    if (!validToken && pathName !== '/login') {
      // verificar se o pathname match com alguma rota de authRoutesExpr
      authRoutesExpr.forEach((route) => {
        if (pathName.match(route)) {
          // redirecionar para a pagina de login
          navigate('/login/Você precisa estar logado para acessar essa página')
        }
      })
    } else {
      // atualizar token
      if ( pathName !== '/logout' ) {
        atualizarToken()
          .then((res) => {
            if (res?.id && !staticData.userData?.id) {
              // atualizar appData.userData
              setAppData((prev) => {
                return {
                  ...prev,
                  userData: {
                    ...prev.userData,
                    ...res
                  }
                }
              })
            }
          })
      }
    }
  }, [isAuth, setIsAuth, location, navigate, setAppData, staticData.userData?.id])
}

export default VerifyAuth
