/*
 * ./components/Home/index.jsx
 */
import React, { useEffect, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import Skelet from '../Common/Skelet'

const Main = lazy(() => import('./Main'))

const Loading = () => {
  return (
    <div className="loading">
      <Skelet count={1} style={{ maxWidth: '768px', height: '250px' }}/>
      <Skelet count={8} style={{ maxWidth: '768px', height: '50px' }}/>
    </div>
  )
}

const Home = () => {
  // mudar titulo da página
  useEffect(() => {
    document.title = 'Automatizando.dev'
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      <section id="home">
        <Main />
      </section>
    </Suspense>
  )
}

Home.propTypes = {
  token: PropTypes.string
}

Home.defaultProps = {
  token: null
}

export default Home
