import React from 'react'
import PropTypes from 'prop-types'

// imports do skeleton
import Skeleton from '@mui/material/Skeleton'

const Skelet = ({ children, ...props }) => {
  return (
    <Skeleton {...props}>
      {children}
    </Skeleton>
  )
}

Skelet.propTypes = {
  children: PropTypes.node,
}

export default Skelet
