/*
 ./index.jsx
 */
import React, { useEffect, useState, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  NavLink,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSwipeable } from "react-swipeable";
import { CheckToken, IsMobile } from "./utils";
import Skelet from "./components/Common/Skelet";
import VerifyAuth from "./components/Common/VerifyAuth";
import "./index.css";

// AppContext defines the GlobalContext of the website
// state shared between all the pages
import AppContext, { GlobalContext } from "./contexts/AppContext";

import Home from "./components/Home";
const Header = lazy(() => import("./components/Header"));
const MobileNavLinks = lazy(() => import("./components/Header/MobileNavLinks"));

const CookieConsent = lazy(() => import("react-cookie-consent"));

// scroll to top
const ScrollToTop = lazy(() => import("./components/Common/ScrollToTop"));

const Footer = lazy(() => import("./components/Footer"));
const Sidebar = lazy(() => import("./components/Sidebar"));

const Toast = lazy(() => import("./components/Common/Toast"));

// import of individual components/pages
// const Home = lazy(() => import('./components/Home'))
const Automacao = lazy(() => import("./components/Automacao"));
const AutomacaoComercial = lazy(
  () => import("./components/AutomacaoComercial"),
);
const BrinquedoFalante = lazy(() => import("./components/Brinquedo"));
const BrinquedoComprar = lazy(() => import("./components/Brinquedo/Comprar"));
const BrinquedoPagamento = lazy(
  () => import("./components/Brinquedo/Pagamento"),
);
const BrinquedoObrigado = lazy(() => import("./components/Brinquedo/Obrigado"));
const BrinquedoErro = lazy(() => import("./components/Brinquedo/Erro"));
const Skills = lazy(() => import("./components/Skills"));
const Chatbots = lazy(() => import("./components/Chatbots"));
const Sobre = lazy(() => import("./components/Sobre"));
const Contato = lazy(() => import("./components/Contato"));
// const Cursos = lazy(() => import("./components/Cursos"));
const PoliticaPrivacidade = lazy(
  () => import("./components/PoliticaPrivacidade"),
);
const TermosDeUso = lazy(() => import("./components/TermosDeUso"));
const NotFound = lazy(() => import("./components/Common/NotFound"));
const Buscar = lazy(() => import("./components/Buscar"));

// import of individual components/pages of Garagem
const Garagem = lazy(() => import("./components/Garagem"));
const Adesao = lazy(() => import("./components/Garagem/Adesao"));
const Pagamento = lazy(() => import("./components/Garagem/Pagamento"));
const Obrigado = lazy(() => import("./components/Garagem/Obrigado"));
const Erro = lazy(() => import("./components/Garagem/Erro"));

// import of Usuario
const Cadastro = lazy(() => import("./components/Usuario/Cadastro"));
const Confirmar = lazy(() => import("./components/Usuario/Confirmar"));
const ConfirmarEmail = lazy(
  () => import("./components/Usuario/ConfirmarEmail"),
);
const Login = lazy(() => import("./components/Usuario/Login"));
const Logout = lazy(() => import("./components/Usuario/Logout"));
const Recuperar = lazy(() => import("./components/Usuario/Recuperar"));
const Reenviar = lazy(() => import("./components/Usuario/Reenviar"));
const ConfirmarCodigo = lazy(
  () => import("./components/Usuario/Recuperar/Confirmar"),
);
const Perfil = lazy(() => import("./components/Usuario/Perfil"));

// import of individual components/pages of Posts
const Artigos = lazy(() => import("./components/Posts"));
const VPost = lazy(() => import("./components/Posts/ViewPost"));

// import of individual components/pages of Forum
const Forum = lazy(() => import("./components/Forum"));
const CreateTopico = lazy(() => import("./components/Forum/CreateTopico"));
const ViewTopico = lazy(() => import("./components/Forum/ViewTopico"));
const EditTopico = lazy(() => import("./components/Forum/EditTopico"));
const DeleteTopico = lazy(() => import("./components/Forum/DeleteTopico"));

// import of Dashboard
const Dashboard = lazy(() => import("./components/Dashboard"));

// import of individual components/pages of Dashboard (Bots)
const DashboardBots = lazy(() => import("./components/Dashboard/Bots"));
const ViewBot = lazy(() => import("./components/Dashboard/Bots/ViewBot"));
const EditBot = lazy(() => import("./components/Dashboard/Bots/EditBot"));
const CreateBot = lazy(() => import("./components/Dashboard/Bots/CreateBot"));
const DeleteBot = lazy(() => import("./components/Dashboard/Bots/DeleteBot"));

// import of individual components/pages of Dashboard (User)
const User = lazy(() => import("./components/Dashboard/User"));
const EditUser = lazy(() => import("./components/Dashboard/User/EditUser"));
const ChangePassword = lazy(
  () => import("./components/Dashboard/User/ChangePassword"),
);
const DeleteUser = lazy(() => import("./components/Dashboard/User/DeleteUser"));

// import of individual components/pages of Dashboard (Mestre Sabe)
const MestreSabe = lazy(() => import("./components/Dashboard/MestreSabe"));
const EditMestreSabe = lazy(
  () => import("./components/Dashboard/MestreSabe/EditMestreSabe"),
);
const ConfirmarMestreSabe = lazy(
  () => import("./components/Dashboard/MestreSabe/ConfirmarMestreSabe"),
);
const DeleteMestreSabe = lazy(
  () => import("./components/Dashboard/MestreSabe/DeleteMestreSabe"),
);
const CriarPersonalidade = lazy(
  () => import("./components/Dashboard/MestreSabe/CriarPersonalidade"),
);
const EditarPersonalidade = lazy(
  () => import("./components/Dashboard/MestreSabe/EditarPersonalidade"),
);

// import of individual components/pages of Dashboard (Brinquedo)
const Brinquedo = lazy(() => import("./components/Dashboard/Brinquedo"));
const ConfirmarBrinquedo = lazy(
  () => import("./components/Dashboard/Brinquedo/ConfirmarBrinquedo"),
);
const ViewBrinquedo = lazy(
  () => import("./components/Dashboard/Brinquedo/ViewBrinquedo"),
);
const BrinquedoCriarPersonalidade = lazy(
  () => import("./components/Dashboard/Brinquedo/CriarPersonalidade"),
);
const BrinquedoEditarPersonalidade = lazy(
  () => import("./components/Dashboard/Brinquedo/EditarPersonalidade"),
);

// import of individual components/pages of Dashboard (Posts)
const Posts = lazy(() => import("./components/Dashboard/Posts"));
const CreatePost = lazy(
  () => import("./components/Dashboard/Posts/CreatePost"),
);
const ViewPost = lazy(() => import("./components/Dashboard/Posts/ViewPost"));
const EditPost = lazy(() => import("./components/Dashboard/Posts/EditPost"));
const DeletePost = lazy(
  () => import("./components/Dashboard/Posts/DeletePost"),
);

// import of individual components/pages of Dashboard (Minha Recepcionista)
const MinhaRecepcionista = lazy(
  () => import("./components/Dashboard/MinhaRecepcionista"),
);

// import of individual components/pages of Dashboard (Hashtags)
const AdminHashtags = lazy(
  () => import("./components/Dashboard/Admin/Hashtags"),
);
const AdminPosts = lazy(() => import("./components/Dashboard/Admin/Posts"));
const AdminUsers = lazy(() => import("./components/Dashboard/Admin/Users"));
const AdminUserEdit = lazy(
  () => import("./components/Dashboard/Admin/Users/Editar"),
);
const AdminUserChangePassword = lazy(
  () => import("./components/Dashboard/Admin/Users/ChangePassword"),
);
const AdminUserDelete = lazy(
  () => import("./components/Dashboard/Admin/Users/DeleteUser"),
);
const AdminUserCreate = lazy(
  () => import("./components/Dashboard/Admin/Users/CreateUser"),
);
const AdminBrinquedos = lazy(
  () => import("./components/Dashboard/Admin/Brinquedos"),
);
const AdminLogs = lazy(() => import("./components/Dashboard/Admin/Logs"));
const AdminCondominios = lazy(
  () => import("./components/Dashboard/Admin/Condominios"),
);
const AdminCondominioCreate = lazy(
  () => import("./components/Dashboard/Admin/Condominios/CreateCondominio"),
);
const AdminCondominioEdit = lazy(
  () => import("./components/Dashboard/Admin/Condominios/Editar"),
);
const AdminCondominioImportUser = lazy(
  () => import("./components/Dashboard/Admin/Condominios/ImportUser"),
);
const AdminCondominioAdicionarDispositivo = lazy(
  () => import("./components/Dashboard/Admin/Condominios/AdicionarDispositivo"),
);
const AdminCondominioEditarDispositivo = lazy(
  () => import("./components/Dashboard/Admin/Condominios/EditarDispositivo"),
);
const AdminCursos = lazy(() => import("./components/Dashboard/Admin/Cursos"));
const AdminCursosCreate = lazy(
  () => import("./components/Dashboard/Admin/Cursos/CreateCurso"),
);
const AdminCursosDelete = lazy(
  () => import("./components/Dashboard/Admin/Cursos/DeleteCurso"),
);
const AdminCursosEditar = lazy(
  () => import("./components/Dashboard/Admin/Cursos/Editar"),
);
const AdminCursosCreateModulo = lazy(
  () => import("./components/Dashboard/Admin/Cursos/CreateModulo"),
);
const AdminCursosEditModulo = lazy(
  () => import("./components/Dashboard/Admin/Cursos/EditModulo"),
);
const AdminCursosDeleteModulo = lazy(
  () => import("./components/Dashboard/Admin/Cursos/DeleteModulo"),
);
const AdminCursosCreateAula = lazy(
  () => import("./components/Dashboard/Admin/Cursos/CreateAula"),
);
const AdminCursosEditAula = lazy(
  () => import("./components/Dashboard/Admin/Cursos/EditAula"),
);
const CursoVerAula = lazy(
  () => import("./components/Dashboard/Cursos/VerAula"),
);
const CursoAvaliar = lazy(
  () => import("./components/Dashboard/Cursos/Avaliar"),
);
const AdminCursosDeleteAula = lazy(
  () => import("./components/Dashboard/Admin/Cursos/DeleteAula"),
);

const AdminPagamentos = lazy(
  () => import("./components/Dashboard/Admin/Pagamentos"),
);

// import of individual components/pages of Dashboard (Videos)
const Videos = lazy(() => import("./components/Dashboard/Videos"));
const CreateVideo = lazy(
  () => import("./components/Dashboard/Videos/CreateVideo"),
);
const ViewVideo = lazy(() => import("./components/Dashboard/Videos/ViewVideo"));
const DeleteVideo = lazy(
  () => import("./components/Dashboard/Videos/DeleteVideo"),
);
const EditVideo = lazy(() => import("./components/Dashboard/Videos/EditVideo"));

// import for individual components/pages of Dashboard (Anexos)
const Anexos = lazy(() => import("./components/Dashboard/Anexos"));
const CreateAnexo = lazy(
  () => import("./components/Dashboard/Anexos/CreateAnexo"),
);
const ViewAnexo = lazy(() => import("./components/Dashboard/Anexos/ViewAnexo"));
const DeleteAnexo = lazy(
  () => import("./components/Dashboard/Anexos/DeleteAnexo"),
);
const EditAnexo = lazy(() => import("./components/Dashboard/Anexos/EditAnexo"));

// import for individual components/pages of Dashboard (Cursos)
const DashboardCursos = lazy(() => import("./components/Dashboard/Cursos"));
const CursoAdesao = lazy(() => import("./components/Dashboard/Cursos/Adesao"));
const CursoPagamento = lazy(
  () => import("./components/Dashboard/Cursos/Pagamento"),
);
const CursoObrigado = lazy(
  () => import("./components/Dashboard/Cursos/Obrigado"),
);
const CursoErro = lazy(() => import("./components/Dashboard/Cursos/Erro"));
const DashboardVerCurso = lazy(
  () => import("./components/Dashboard/Cursos/VerCurso"),
);

// import for individual components/pages of Dashboard (Inbox)
const Inbox = lazy(() => import("./components/Dashboard/Inbox"));

// gerador-de-imagens
const GeradorDeImagens = lazy(
  () => import("./components/Dashboard/GeradorDeImagens"),
);

const Newsletter = lazy(() => import("./components/Dashboard/Newsletter"));
const CreateList = lazy(
  () => import("./components/Dashboard/Newsletter/CreateList"),
);
const ViewList = lazy(
  () => import("./components/Dashboard/Newsletter/ViewList"),
);
const CreateModel = lazy(
  () => import("./components/Dashboard/Newsletter/CreateModel"),
);
const ViewModel = lazy(
  () => import("./components/Dashboard/Newsletter/ViewModel"),
);
const CreateDisparo = lazy(
  () => import("./components/Dashboard/Newsletter/CreateDisparo"),
);

// componente ScrollToTop
// faz o scroll para o topo da pagina quando a rota muda
const AutoScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
};

const Loading = () => {
  return (
    <div className="loading">
      <Skelet count={1} style={{ maxWidth: "768px", height: "250px" }} />
      <Skelet count={8} style={{ maxWidth: "768px", height: "50px" }} />
    </div>
  );
};

// get initial state of sidebar
const getInitialSidebarState = () => {
  const storedValue = localStorage.getItem("showSidebar");
  if (storedValue === null) {
    return true;
  }
  return storedValue === "true";
};

const App = () => {
  const [isAuth, setIsAuth] = useState(CheckToken());

  // indica se o menu mobile esta aberto ou nao
  // vai para Header/index e depois para AccountLinks
  const [isOpen, setIsOpen] = useState(false);

  const [showSidebar, setShowSidebar] = useState(getInitialSidebarState());
  const isMobile = IsMobile();
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true",
  );
  const [theme, setTheme] = useState(
    createTheme({
      palette: {
        mode: isDarkMode ? "dark" : "light",
        primary: {
          main: "rgb(57, 81, 179) !important",
        },
      },
    }),
  );

  // update do tema
  useEffect(() => {
    setTheme(
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            main: "rgb(57, 81, 179) !important",
          },
        },
      }),
    );

    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  // swipe de abrir e fechar o menu mobile
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsOpen(true),
    onSwipedRight: () => setIsOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 150,
  });

  return (
    <AppContext>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AutoScrollToTop />
          <Header
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          <div {...handlers}>
            <GlobalContext.Consumer>
              {() => {
                return (
                  <div
                    className="main-container"
                    data-has-sidebar={showSidebar}
                  >
                    <VerifyAuth isAuth={isAuth} setIsAuth={setIsAuth} />
                    <Suspense fallback={<Loading />}>
                      <Routes>
                        {/* Routes of the website */}
                        <Route path="/" element={<Home />} />
                        <Route
                          path="/automacao-residencial"
                          element={<Automacao />}
                        />
                        <Route
                          path="/automacao-comercial"
                          element={<AutomacaoComercial />}
                        />
                        <Route
                          path="/brinquedo"
                          element={<BrinquedoFalante />}
                        />
                        <Route
                          path="/brinquedo/comprar"
                          element={<BrinquedoComprar />}
                        />
                        <Route
                          path="/brinquedo/comprar/pagamento"
                          element={<BrinquedoPagamento />}
                        />
                        <Route
                          path="/brinquedo/comprar/obrigado"
                          element={<BrinquedoObrigado />}
                        />
                        <Route
                          path="/brinquedo/comprar/erro"
                          element={<BrinquedoErro />}
                        />
                        <Route path="/alexa-skills" element={<Skills />} />
                        <Route path="/chatbots" element={<Chatbots />} />
                        <Route path="/sobre" element={<Sobre />} />
                        <Route path="/contato" element={<Contato />} />
                        <Route
                          path="/cursos/:id"
                          element={<DashboardVerCurso />}
                        />

                        <Route path="/cursos" element={<DashboardCursos />} />
                        <Route
                          path="/cursos/adesao/pagamento/:id"
                          element={<CursoPagamento />}
                        />
                        <Route
                          path="/cursos/adesao/:id"
                          element={<CursoAdesao />}
                        />
                        <Route
                          path="/cursos/comprar/obrigado"
                          element={<CursoObrigado />}
                        />
                        <Route
                          path="/cursos/comprar/erro"
                          element={<CursoErro />}
                        />
                        <Route
                          path="/politica-de-privacidade"
                          element={<PoliticaPrivacidade />}
                        />
                        <Route
                          path="/termos-de-uso"
                          element={<TermosDeUso />}
                        />
                        <Route path="/buscar/:q?" element={<Buscar />} />

                        {/* Routes of Login */}
                        <Route path="/cadastro" element={<Cadastro />} />
                        <Route
                          path="/confirmar-cadastro/:hash?"
                          element={<Confirmar />}
                        />
                        <Route
                          path="/confirmar-email/:hash?"
                          element={<ConfirmarEmail />}
                        />
                        <Route path="/login/:message?" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route
                          path="/recuperar-senha/:hash?"
                          element={<Recuperar />}
                        />
                        <Route
                          path="/confirmar-codigo"
                          element={<ConfirmarCodigo />}
                        />
                        <Route
                          path="/reenviar-codigo/:email?"
                          element={<Reenviar />}
                        />
                        <Route path="/u/:username" element={<Perfil />} />

                        {/* Routes of Posts */}
                        <Route path="/posts" element={<Artigos />} />
                        <Route path="/post/:post_url" element={<VPost />} />

                        {/* Routes of Forum */}
                        <Route path="/forum" element={<Forum />} />
                        <Route
                          path="/forum/topic/create"
                          element={<CreateTopico />}
                        />
                        <Route
                          path="/topico/:topico_url"
                          element={<ViewTopico />}
                        />
                        <Route
                          path="/topico/edit/:topico_id"
                          element={<EditTopico />}
                        />
                        <Route
                          path="/topico/delete/:topico_id"
                          element={<DeleteTopico />}
                        />

                        {/* Routes of Garagem */}
                        <Route path="/garagem" element={<Garagem />} />
                        <Route
                          path="/garagem/adesao/:plano_id?"
                          element={<Adesao />}
                        />
                        <Route
                          path="/garagem/adesao/pagamento"
                          element={<Pagamento />}
                        />
                        <Route
                          path="/garagem/adesao/obrigado"
                          element={<Obrigado />}
                        />
                        <Route path="/garagem/adesao/erro" element={<Erro />} />

                        {/* Routes of Dashboard - Private */}
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Routes of Dashboard/Bots - Private */}
                        <Route
                          path="/dashboard/bots"
                          element={<DashboardBots />}
                        />
                        <Route
                          path="/dashboard/bots/viewBot/:bot_id"
                          element={<ViewBot />}
                        />
                        <Route
                          path="/dashboard/bots/editBot/:bot_id"
                          element={<EditBot />}
                        />
                        <Route
                          path="/dashboard/bots/createBot"
                          element={<CreateBot />}
                        />
                        <Route
                          path="/dashboard/bots/deleteBot/:bot_id"
                          element={<DeleteBot />}
                        />

                        {/* Routes of Dashboard/User - Private */}
                        <Route path="/dashboard/user" element={<User />} />
                        <Route
                          path="/dashboard/user/editUser"
                          element={<EditUser />}
                        />
                        <Route
                          path="/dashboard/user/changePassword"
                          element={<ChangePassword />}
                        />
                        <Route
                          path="/dashboard/user/deleteUser"
                          element={<DeleteUser />}
                        />

                        {/* Routes of Dashboard/Mestre Sabe - Private */}
                        <Route
                          path="/dashboard/mestre-sabe"
                          element={<MestreSabe />}
                        />
                        <Route
                          path="/dashboard/mestre-sabe/edit"
                          element={<EditMestreSabe />}
                        />
                        <Route
                          path="/dashboard/mestre-sabe/confirmar"
                          element={<ConfirmarMestreSabe />}
                        />
                        <Route
                          path="/dashboard/mestre-sabe/delete"
                          element={<DeleteMestreSabe />}
                        />
                        <Route
                          path="/dashboard/mestre-sabe/criar-personalidade"
                          element={<CriarPersonalidade />}
                        />
                        <Route
                          path="/dashboard/mestre-sabe/editar-personalidade/:personalidade_id"
                          element={<EditarPersonalidade />}
                        />

                        {/* Routes of Dashboard/Brinquedo - Private */}
                        <Route
                          path="/brinquedo"
                          element={
                            <Navigate to="/dashboard/brinquedo" replace />
                          }
                        />
                        <Route
                          path="/dashboard/brinquedo"
                          element={<Brinquedo />}
                        />
                        <Route
                          path="/dashboard/brinquedo/confirmar"
                          element={<ConfirmarBrinquedo />}
                        />
                        <Route
                          path="/dashboard/brinquedo/view/:id"
                          element={<ViewBrinquedo />}
                        />
                        <Route
                          path="/dashboard/brinquedo/criar-personalidade/:id"
                          element={<BrinquedoCriarPersonalidade />}
                        />
                        <Route
                          path="/dashboard/brinquedo/editar-personalidade/:personalidade_id"
                          element={<BrinquedoEditarPersonalidade />}
                        />

                        {/* Routes of Dashboard/Posts - Private */}
                        <Route path="/dashboard/posts" element={<Posts />} />
                        <Route
                          path="/dashboard/posts/create"
                          element={<CreatePost />}
                        />
                        <Route
                          path="/dashboard/posts/view/:post_id"
                          element={<ViewPost />}
                        />
                        <Route
                          path="/dashboard/posts/edit/:post_id"
                          element={<EditPost />}
                        />
                        <Route
                          path="/dashboard/posts/delete/:post_id"
                          element={<DeletePost />}
                        />

                        {/* Routes of Dashboard/Minha Recepcionista - Private */}
                        <Route
                          path="/dashboard/minha-recepcionista"
                          element={<MinhaRecepcionista />}
                        />

                        {/* Routes of Dashboard/admin - Private */}
                        <Route
                          path="/dashboard/admin/hashtags"
                          element={<AdminHashtags />}
                        />
                        <Route
                          path="/dashboard/admin/posts"
                          element={<AdminPosts />}
                        />

                        <Route
                          path="/dashboard/admin/users"
                          element={<AdminUsers />}
                        />
                        <Route
                          path="/dashboard/admin/users/editar/:id"
                          element={<AdminUserEdit />}
                        />
                        <Route
                          path="/dashboard/admin/users/mudar-senha/:id"
                          element={<AdminUserChangePassword />}
                        />
                        <Route
                          path="/dashboard/admin/users/deletar/:id"
                          element={<AdminUserDelete />}
                        />
                        <Route
                          path="/dashboard/admin/users/criar"
                          element={<AdminUserCreate />}
                        />

                        <Route
                          path="/dashboard/admin/condominios"
                          element={<AdminCondominios />}
                        />
                        <Route
                          path="/dashboard/admin/condominios/criar"
                          element={<AdminCondominioCreate />}
                        />
                        <Route
                          path="/dashboard/admin/condominios/editar/:id"
                          element={<AdminCondominioEdit />}
                        />
                        <Route
                          path="/dashboard/admin/condominios/importar-usuario/:id"
                          element={<AdminCondominioImportUser />}
                        />
                        <Route
                          path="/dashboard/admin/condominios/adicionar-dispositivo/:id"
                          element={<AdminCondominioAdicionarDispositivo />}
                        />
                        <Route
                          path="/dashboard/admin/condominios/editar-dispositivo/:id"
                          element={<AdminCondominioEditarDispositivo />}
                        />

                        <Route
                          path="/dashboard/admin/logs"
                          element={<AdminLogs />}
                        />
                        <Route
                          path="/dashboard/admin/brinquedos"
                          element={<AdminBrinquedos />}
                        />
                        <Route
                          path="/dashboard/admin/cursos"
                          element={<AdminCursos />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/criar"
                          element={<AdminCursosCreate />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:id/deletar"
                          element={<AdminCursosDelete />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:id"
                          element={<AdminCursosEditar />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/adicionar-modulo"
                          element={<AdminCursosCreateModulo />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/:modulo_id/excluir-modulo"
                          element={<AdminCursosDeleteModulo />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/:modulo_id"
                          element={<AdminCursosEditModulo />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/:modulo_id/adicionar-aula"
                          element={<AdminCursosCreateAula />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/:modulo_id/aula/:id"
                          element={<AdminCursosEditAula />}
                        />
                        <Route
                          path="/dashboard/admin/cursos/:curso_id/:modulo_id/aula/:id/excluir"
                          element={<AdminCursosDeleteAula />}
                        />
                        <Route
                          path="/dashboard/cursos/:curso_id/aula/:id"
                          element={<CursoVerAula />}
                        />
                        <Route
                          path="/cursos/:curso_id/aula/:id"
                          element={<CursoVerAula />}
                        />

                        {/* Routes of Dashboard/Videos - Private */}
                        <Route path="/dashboard/videos" element={<Videos />} />
                        <Route
                          path="/dashboard/videos/create"
                          element={<CreateVideo />}
                        />
                        <Route
                          path="/dashboard/videos/view/:video_id"
                          element={<ViewVideo />}
                        />
                        <Route
                          path="/dashboard/videos/edit/:video_id"
                          element={<EditVideo />}
                        />
                        <Route
                          path="/dashboard/videos/delete/:video_id"
                          element={<DeleteVideo />}
                        />

                        {/* Routes of Dashboard/Videos - Private */}
                        <Route path="/dashboard/anexos" element={<Anexos />} />
                        <Route
                          path="/dashboard/anexos/create"
                          element={<CreateAnexo />}
                        />
                        <Route
                          path="/dashboard/anexos/view/:anexo_id"
                          element={<ViewAnexo />}
                        />
                        <Route
                          path="/dashboard/anexos/delete/:anexo_id"
                          element={<DeleteAnexo />}
                        />
                        <Route
                          path="/dashboard/anexos/edit/:anexo_id"
                          element={<EditAnexo />}
                        />

                        {/* Routes of Dashboard/Cursos - Private */}
                        <Route
                          path="/dashboard/cursos"
                          element={<DashboardCursos />}
                        />
                        <Route
                          path="/dashboard/cursos/:id"
                          element={<DashboardVerCurso />}
                        />

                        <Route
                          path="/dashboard/cursos/:id/avaliar"
                          element={<CursoAvaliar />}
                        />
                        <Route
                          path="/cursos/:id/avaliar"
                          element={<CursoAvaliar />}
                        />

                        {/* Routes of Dashboard/Inbox - Private */}
                        <Route path="/dashboard/inbox" element={<Inbox />} />

                        {/* Routes of Dashboard/GeradorDeImagens - Private */}
                        <Route
                          path="/dashboard/gerador-de-imagens"
                          element={<GeradorDeImagens />}
                        />

                        {/* Routes of Dashboard/Admin/Pagamentos - Private */}
                        <Route
                          path="/dashboard/admin/pagamentos"
                          element={<AdminPagamentos />}
                        />

                        {/* Routes of Dashboard/Newsletter - Private */}
                        <Route
                          path="/dashboard/newsletter"
                          element={<Newsletter />}
                        />
                        <Route
                          path="/dashboard/newsletter/create-list"
                          element={<CreateList />}
                        />
                        <Route
                          path="/dashboard/newsletter/list/:nome"
                          element={<ViewList />}
                        />
                        <Route
                          path="/dashboard/newsletter/create-model"
                          element={<CreateModel />}
                        />
                        <Route
                          path="/dashboard/newsletter/model/:id"
                          element={<ViewModel />}
                        />
                        <Route
                          path="/dashboard/newsletter/create-disparo"
                          element={<CreateDisparo />}
                        />

                        {/* rota padrão 404: */}
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>

                      {!isMobile ? (
                        <Sidebar showSidebar={showSidebar} />
                      ) : (
                        <MobileNavLinks isOpen={isOpen} setIsOpen={setIsOpen} />
                      )}
                    </Suspense>
                  </div>
                );
              }}
            </GlobalContext.Consumer>
            <CookieConsent
              location="bottom"
              buttonText="Aceitar"
              cookieName="cookieConsent"
              style={{ background: "var(--color-blue-logo)", zIndex: "9999" }}
              buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
              expires={7}
            >
              Este site usa cookies para melhorar sua experiência. Ao continuar
              navegando, você concorda com a nossa{" "}
              <NavLink to="/politica-de-privacidade">
                Política de Privacidade
              </NavLink>
              .
            </CookieConsent>
            <ScrollToTop />
            <Toast />
            <Footer />
          </div>
          {/* </div> => era do swipe bar */}
        </BrowserRouter>
      </ThemeProvider>
    </AppContext>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
